<template>
    <div class="box">
        <h2>
            Regeln für Antragsdaten prüfen
            <small>Prüfung wann ein Antrag in die Aufgabe "Antragsdaten prüfen" aufgenommen werden soll.</small>
        </h2>
        <div class="body">
            <h3 style="margin:0; padding:0;">Blacklist der Filiale</h3>
            <small>Die hier eingegebenen Begriffe können irgendwo im Filialnamen, auch innerhalb von Wörtern vorkommen. Groß- und Kleinschreibung wird dabei nicht beachtet.</small>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(filter, index) in blacklist" v-bind:key="index">
                        <td>
                            <div class="form-group form-float no-error">
                                <input v-model="blacklist[index]" :name="'blacklist_' + index" v-validate="'required'" :id="'blacklist_' + index" placeholder=" " type="text" />
                                <label :for="'blacklist_' + index">Filter</label>
                                <span class="error" v-show="errors.has('blacklist_' + index)">{{ errors.first("blacklist_" + index) }}</span>
                            </div>
                        </td>
                        <td><i @click="removeBlacklist(index)" class="delete fal fa-trash-alt"></i></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <span class="add" @click="addBlacklist()"><i class="add fal fa-plus-circle"></i> Hinzufügen</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <hr />
            <h3>Limits</h3>
            <div class="form-group form-float no-error" style="margin-bottom: 32px;">
                <input v-model="stichprobe" name="stichprobe" v-validate="'required|numeric'" id="stichprobe" placeholder=" " type="text" />
                <label for="stichprobe">Stichprobe in %</label>
                <span class="error" v-show="errors.has('stichprobe')">{{ errors.first("stichprobe") }}</span>
            </div>
            <div class="form-group form-float no-error">
                <input v-model="limit" name="limit" v-validate="'required|numeric'" id="limit" placeholder=" " type="text" />
                <label for="limit">Summenlimit in €</label>
                <span class="error" v-show="errors.has('limit')">{{ errors.first("limit") }}</span>
            </div>
            <hr />
            <div class="button-row">
                <button @click="save()" type="button" class="primary size-l"><i class="fal fa-save"></i> Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            blacklist: [],
            stichprobe: 5,
            limit: 100000
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        addBlacklist() {
            this.blacklist.push("");
        },
        removeBlacklist(index) {
            this.blacklist.splice(index, 1);
        },
        save() {
            var settings = {
                blacklist: [],
                stichprobe: 0,
                limit: 0
            };
            for (let f = 0; f < this.blacklist.length; f++) {
                if (this.blacklist[f].trim() == "") {
                    continue;
                }

                settings.blacklist.push(this.blacklist[f]);
            }

            settings.stichprobe = parseInt(this.stichprobe, 10);
            settings.limit = parseInt(this.limit, 10);

            this.$api.put("/bsv/antragsdaten_pruefen", settings).then(
                () => {
                    this.$snotify.success("Filter gespeichert");
                },
                () => {
                    this.$snotify.error("Speichern fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.$api.get("/bsv/antragsdaten_pruefen").then(
                response => {
                    if (response.data.data === null) {
                        response.data.data = {
                            blacklist: [],
                            stichprobe: 5,
                            limit: 100000
                        };
                    }
                    if (response.data.data.blacklist === null) {
                        response.data.data.blacklist = [];
                    }

                    this.blacklist = response.data.data.blacklist;
                    this.stichprobe = response.data.data.stichprobe;
                    this.limit = response.data.data.limit;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Einstellungen");
                }
            );
        }
    }
};
</script>
